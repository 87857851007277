<template>
  <section id="jobs" class="">
    <div class="container">
      <div class="row">
        <div class="col-lg-12 text-center">
          <div class="navy-line"></div>
          <h1>Aviso de Privacidad</h1>
          <p>
            Todo acerca de como será utilizada tu información.
          </p>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-12 col-md-12 col-sm-12" style="min-height:73vh;">
          <embed
            src="./docs/avisodeprivacidad.pdf"
            class="col-md-12"
            style="min-height:73vh;"
            alt="pdf"
          />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {};
</script>

<style scoped>
.navy-line {
  margin-top: 0;
}
</style>
